import { Script, ScriptStrategy } from "gatsby";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

import { heroType } from "../constants/constants";
import GlobalFlatFooter from "../global-flat-footer/global-flat-footer";
import GlobalFooter from "../global-footer/global-footer";
import PageModularBlock from "../page/page-modular-block/page-modular-block";
import EmbeddedScript from "../shared/components/embedded-script/embedded-script";
import HeroWrapper from "../shared/components/hero-wrapper/hero-wrapper";
import SEO from "../shared/components/seo/seo";
import { AppDataContext } from "../shared/contexts/app-data/AppDataContext";
import { UiStateContext } from "../shared/contexts/ui-state/UiStateContext";
import usePageHasScrolled from "../shared/hooks/page-has-scrolled/usePageHasScrolled";
import { DeviceType } from "../shared/hooks/use-breakpoint/device-type";
import useBreakpoint from "../shared/hooks/use-breakpoint/useBreakpoint";
import useFeatureToggle from "../shared/hooks/use-feature-toggle/useFeatureToggle";
import {
  GlobalAlertsContent,
  GlobalNavContent,
  GlobalSettings,
  HomePageContent,
  PrimaryHeaderContent,
  GlobalFooterContent,
  IconPack,
  HighImpactContainerHeroContent,
  GlobalToolbarContent,
  RegionalLinksModalContent,
  GlobalFlatFooterContent
} from "../shared/models/app-data-model";
import StickyHeader from "../sticky-header/sticky-header";
import "./home-page.scss";

export type HomePageQueryResponse = {
  allShPages: ShPageEdgeResults;
  shSpectrumhealthOrgHomepage: HomePageContent;
  shSpectrumhealthOrgPrimaryHeader: PrimaryHeaderContent;
  shGlobalHeaderNav: GlobalNavContent; // Global Nav for corewellhealth.org
  shSpectrumHealthOrgGlobalNav: GlobalNavContent; // Global Nav for spectrumhealth.org
  shGlobalAlertsSpectrumhealthOrg: GlobalAlertsContent;
  shSpectrumhealthOrgGlobalSettings: GlobalSettings;
  shSpectrumhealthOrgGlobalFooterNav?: GlobalFooterContent;
  shGlobalToolbar: GlobalToolbarContent; //Global Toolbar for corewellhealth.org
  shGlobalFlatFooter?: GlobalFlatFooterContent;
  shRegionalLinksModal?: RegionalLinksModalContent;
};

export type ShPageEdgeResults = {
  edges: ShPageEdge[];
};

export type ShPageEdge = {
  node: ShPage;
};

export type ShPage = {
  id: string;
  title: string;
  url: string;
};

export type HomePageProps = {
  data: HomePageQueryResponse;
  corewellStyleOverrides?: boolean;
  clearStepWidget?: string;
  clearStepResizeScript?: string;
};

/**
 * This renders our actual homepage!
 * If you need to update the query, please check pages/index.tsx
 */
const HomePage: React.FC<HomePageProps> = (props: HomePageProps) => {
  const globalNavContent: GlobalNavContent = props.data.shGlobalHeaderNav ?? props.data.shSpectrumHealthOrgGlobalNav;
  const appDataContext = useContext(AppDataContext);
  const uiStateContext = useContext(UiStateContext);
  const [menusOpen, setMenusOpen] = useState(uiStateContext.menuOpen || uiStateContext.auxNavOpen || uiStateContext.subsiteMenuOpen || uiStateContext.megaMenuOpen);
  const pageRef = useRef<HTMLDivElement>(null);
  const staticIcons = props.data?.shSpectrumhealthOrgGlobalSettings.site_theme.find((x) => x.icon_pack)?.icon_pack as IconPack[];
  const siteName = props.data.shSpectrumhealthOrgGlobalSettings.title;
  const deviceType = useBreakpoint();
  const globalToolbarContent: GlobalToolbarContent = props.data.shGlobalToolbar;
  const fullBleedHeroPresent =
    props.data.shSpectrumhealthOrgHomepage.hero?.[0]?.internal?.type === heroType.high ||
    props.data.shSpectrumhealthOrgHomepage.hero?.[0]?.internal?.type === heroType.reducedHeight;
  const featureToggles = props.data.shSpectrumhealthOrgGlobalSettings.feature_toggles;
  const useGlobalFlatFooter = useFeatureToggle("global-flat-footer", featureToggles);
  const conversationAgentEnabled = useFeatureToggle("clearstep-conversation-agent", featureToggles);

  usePageHasScrolled(pageRef, props.data?.shSpectrumhealthOrgGlobalSettings.feature_toggles);

  /**
   * this hook sets the subsite data when we land on a page
   * */
  useEffect(() => {
    appDataContext.setSubsiteData?.(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDataContext.setSubsiteData]);

  /**
   * this hook sets the regional links modal data when we land on homepage
   * */
  useEffect(() => {
    appDataContext.setRegionalLinksModalData?.(props.data.shRegionalLinksModal as RegionalLinksModalContent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDataContext.setRegionalLinksModalData]);

  /**
   * This effect checks if our menus are open and sets our menusOpen bool
   */
  useEffect(() => {
    setMenusOpen(uiStateContext.menuOpen || uiStateContext.auxNavOpen || uiStateContext.subsiteMenuOpen || uiStateContext.megaMenuOpen);
  }, [setMenusOpen, uiStateContext.menuOpen, uiStateContext.auxNavOpen, uiStateContext.subsiteMenuOpen, uiStateContext.megaMenuOpen]);

  const seo = props.data?.shSpectrumhealthOrgHomepage && props.data?.shSpectrumhealthOrgHomepage?.seo && (
    <SEO
      siteName={props.data.shSpectrumhealthOrgGlobalSettings.title}
      // TODO could switch site url based on active ENV
      siteUrl={props.data.shSpectrumhealthOrgGlobalSettings.prod_url}
      title={props.data.shSpectrumhealthOrgHomepage.seo.title || props.data.shSpectrumhealthOrgHomepage.title}
      facebookDomainVerification={props.data.shSpectrumhealthOrgGlobalSettings.facebook_domain_verification}
      twitterCreator={props.data.shSpectrumhealthOrgGlobalSettings.twitter_creator}
      twitterSite={props.data.shSpectrumhealthOrgGlobalSettings.twitter_site}
      description={props.data.shSpectrumhealthOrgHomepage.seo.description || props.data.shSpectrumhealthOrgGlobalSettings.title}
      defaultSiteImage={props?.data?.shSpectrumhealthOrgPrimaryHeader?.logo?.url}
      pagePath="/"
      keywords={props.data.shSpectrumhealthOrgHomepage.seo.keywords || ""}
      schema={`{
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Organization",
                "name": "${siteName}",
                "image": "${props.data.shSpectrumhealthOrgHomepage.seo.image?.url}"
              }
            }`}
    />
  );

  const embedScripts = appDataContext.embeddedScripts ? (
    <EmbeddedScript embedScriptBlocks={appDataContext.embeddedScripts} featureToggles={featureToggles} pagePath={"/"} />
  ) : null;

  const hero = props.data?.shSpectrumhealthOrgHomepage && props.data?.shSpectrumhealthOrgHomepage?.hero && (
    <HeroWrapper
      iconPack={props.data.shSpectrumhealthOrgGlobalSettings?.site_theme?.find((x) => x.icon_pack)?.icon_pack as IconPack[]}
      featureToggles={featureToggles}
      content={props.data?.shSpectrumhealthOrgHomepage?.hero}
      subsiteNavPresent={false}
      headerPresent={true}
      corewellStyleOverrides={props?.corewellStyleOverrides}
    ></HeroWrapper>
  );

  const modularBlocks =
    props.data?.shSpectrumhealthOrgHomepage && props.data?.shSpectrumhealthOrgHomepage?.modular_blocks
      ? props.data?.shSpectrumhealthOrgHomepage.modular_blocks.map((item, index) => (
          <PageModularBlock
            blockContent={item}
            featureToggles={featureToggles}
            iconPack={staticIcons}
            key={index}
            corewellStyleOverrides={props?.corewellStyleOverrides}
          />
        ))
      : null;

  return (
    <>
      <StickyHeader
        featureToggles={featureToggles}
        globalNavContent={globalNavContent}
        primaryHeaderContent={props?.data?.shSpectrumhealthOrgPrimaryHeader}
        dynamicPrimaryHeader={fullBleedHeroPresent}
        staticIcons={staticIcons}
        campaignPageActive={false}
        lightFontMode={
          !uiStateContext.pageHasScrolled &&
          fullBleedHeroPresent &&
          (props.data?.shSpectrumhealthOrgHomepage.hero?.[0] as unknown as HighImpactContainerHeroContent)?.overlay_text_color?.color ===
            "light"
        }
        title={props?.data?.shSpectrumhealthOrgGlobalSettings?.title}
        globalToolbarContent={globalToolbarContent}
        corewellStyleOverrides={props.corewellStyleOverrides}
      />
      {conversationAgentEnabled && props.clearStepWidget && props.clearStepResizeScript && !uiStateContext?.megaMenuOpen && !uiStateContext.menuOpen && (
        <>
          <Helmet>
            <script type="text/javascript" src={props.clearStepResizeScript}></script>
          </Helmet>
          <iframe id="widget-iframe" src={props.clearStepWidget} title="Clearstep Symptom Check Widget" style={{ width: 0, height: 0 }}></iframe>
        </>
      )}
      {/* todo: remove the hidden below when we can fix the modal/menu overflow scroll issue, it would be better not to have this */}
      <div hidden={deviceType < DeviceType.Desktop && menusOpen}>
        {seo}
        {embedScripts}
        <div ref={pageRef} className="page" id="page-content">
          <div>{hero}</div>
          <div className="modular-block-container">{modularBlocks}</div>
        </div>
        {useGlobalFlatFooter ? (
          <GlobalFlatFooter
            content={props.data?.shGlobalFlatFooter as GlobalFlatFooterContent}
            copyrightSubtext={props.data?.shSpectrumhealthOrgGlobalSettings?.copyright_subtext}
            iconPack={staticIcons}
            title={props.data?.shSpectrumhealthOrgGlobalSettings.title}
          />
        ) : (
          <GlobalFooter
            iconPack={staticIcons}
            content={props.data?.shSpectrumhealthOrgGlobalFooterNav as GlobalFooterContent}
            copyrightSubtext={props.data?.shSpectrumhealthOrgGlobalSettings?.copyright_subtext}
            title={props.data?.shSpectrumhealthOrgGlobalSettings.title}
          />
        )}
      </div>
    </>
  );
};

export default HomePage;
