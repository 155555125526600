import HomePage, { HomePageProps } from "../../../design-system/home-page/home-page";
import { graphql } from "gatsby";
import React from "react";
import globalSettings from "../../global-settings.json";

/**
 * This file just creates a static page at root (./index.html)
 * and we simply have it load our HomePage component
 */
const IndexPage: React.FC<HomePageProps> = (props: HomePageProps) => {
  const clearStepWidget = globalSettings?.entry?.clearstep_widget_source;
  const clearStepResizeScript = globalSettings?.entry?.clearstep_resize_script_source;

  return <HomePage data={props.data} corewellStyleOverrides={true} clearStepWidget={clearStepWidget} clearStepResizeScript={clearStepResizeScript} />;
};

export const homePageQuery = graphql`
  query {
    shGlobalHeaderNav {
      ...globalNavFragment
    }
    shMegaMenu {
      ...megaMenuFragment
    }
    shSpectrumhealthOrgPrimaryHeader {
      ...primaryHeaderFragment
    }
    shSpectrumhealthOrgGlobalSettings {
      ...globalSettingsFragment
    }
    shSpectrumhealthOrgHomepage {
      ...homePageFragment
    }
    shSpectrumhealthOrgGlobalFooterNav {
      ...globalFooterFragment
    }
    shGlobalToolbar {
      ...globalToolbarFragment
    }
    shRegionalLinksModal {
      ...regionalLinksModalFragment
    }
    shGlobalFlatFooter {
      ...shGlobalFlatFooterFragment
    }
  }
`;

export default IndexPage;
